import numberConverter from 'number-to-words';
import {
  capitalize, each, find, includes,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';

const ordinalFromNumber = (number) => {
  try {
    return capitalize(numberConverter.toOrdinal(number));
  } catch (e) {
    return number;
  }
};
const teamFromIndex = (index) => (!index ? 'Home' : 'Away');

export const MARKET_TEMPLATE_MAP = {
  // Soccer
  RESULT: () => 'Match Winner',
  RESULT_EXC_OT: () => 'Match Winner (excl. OT)',
  GOAL_OVER_UNDER: () => 'Goal Over / Under',
  HALF_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Winner`,

  // Basketball
  HALF_TIME_FULL_TIME: () => 'Half Time / Full Time',
  POINT_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Points`,
  HALF_POINT_RACE_TO_X: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Race to ${marketTemplate.template.raceTo} Points`,
  PERIOD_POINT_RACE_TO_X: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Race to ${marketTemplate.template.raceTo} Points`,
  POINT_HANDICAP_INC_OT: () => 'Point Spread (incl. OT)',
  HALF_POINT_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Point Spread`,
  PERIOD_POINT_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Point Spread`,
  POINT_MARGIN: () => 'Total Points Margin',
  POINT_HANDICAP: () => 'Point Spread',
  HALF_POINT_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Odd / Even`,
  HALF_POINT_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Over / Under`,
  POINT_ODD_EVEN_INC_OT: () => 'Total Points Odd / Even (incl. OT)',
  POINT_OVER_UNDER_INC_OT: () => 'Total Points Over / Under (incl. OT)',
  HIGHEST_SCORING_HALF: () => 'Highest Scoring Half',
  HIGHEST_SCORING_PERIOD: () => 'Highest Scoring Period',
  NEXT_POINT: () => 'Next Point',
  PERIOD_POINT_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Odd / Even Total Points`,
  PERIOD_POINT_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Over / Under Total Points`,
  PERIOD_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Winner`,
  POINT_ODD_EVEN: () => 'Odd / Even Total Points',
  POINT_OVER_UNDER: () => 'Over / Under Total Points',
  TEAM_HALF_POINT_ODD_EVEN: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Odd / Even Total Points`,
  TEAM_HALF_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Over / Under Total Points`,
  TEAM_PERIOD_POINT_ODD_EVEN: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Period Odd / Even Total Points`,
  TEAM_PERIOD_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Period Over / Under Total Points`,
  TEAM_POINT_ODD_EVEN: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Odd / Even Total Points`,
  TEAM_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Over / Under Total Points`,
  TEAM_POINT_OVER_UNDER_INC_OT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Points Over / Under (incl. OT)`,
  TO_GO_TO_OVERTIME: () => 'Will there be Overtime?',
  TWO_WAY_HALF_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Winner Money Line`,
  TWO_WAY_PERIOD_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Winner Money Line`,
  TWO_WAY_RESULT: () => 'Match Winner Money Line',
  TWO_WAY_RESULT_INC_OT: () => 'Match Winner Money Line (incl. OT)',
  TWO_WAY_RESULT_EXC_OT: () => 'Match Winner Money Line (excl. OT)',
  HALF_TIME_FULL_TIME_NINE_SELECTIONS_EXC_OT: () => 'Half Time / Full Time (excl. OT) 9Way',
  HALF_TIME_FULL_TIME_SIX_SELECTIONS_EXC_OT: () => 'Half Time / Full Time (excl. OT) 6Way',
  PLAYER_POINT_OVER_UNDER: () => 'Player Points',
  PLAYER_ASSIST_OVER_UNDER: () => 'Player Assists',
  PLAYER_THREE_POINTER_OVER_UNDER: () => 'Player Threes',
  PLAYER_POINT_BANDS: () => 'Player Point Bands',
  PLAYER_REBOUND_OVER_UNDER: () => 'Player Rebounds',
  NEXT_FIELD_GOAL_TYPE: () => 'Next Field Goal Type',
  NEXT_FIELD_GOAL_TYPE_EXACT: () => 'Next Field Goal Exact Type',
  TWO_WAY_NEXT_TEAM_TO_SCORE_FIELD_GOAL: () => 'Next Team to Score a Field Goal 2Way',
  TEAM_NEXT_FIELD_GOAL_TYPE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Next Field Goal Type`,
  TEAM_NEXT_POSSESSION: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Next Possession`,
  TEAM_NEXT_POSSESSION_EXACT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Next Possession Exact`,
  TWO_WAY_POINT_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Points 2Way`,

  // Ultimate
  HALF_TIME_FULL_TIME_FOUR_SELECTIONS: () => 'Half Time / Full Time',
  HALF_TIME_FULL_TIME_FOUR_SELECTIONS_EXC_OT: () => 'Half Time / Full Time (excl. OT) 4Way',
  PREMATCH_POINT_ASIAN_HANDICAP: () => 'Point Asian Handicap',
  THREE_WAY_HALF_POINT_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Point Spread 3Way`,
  THREE_WAY_HALF_POINT_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Over / Under 3Way`,
  THREE_WAY_PERIOD_POINT_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Point Spread 3Way`,
  THREE_WAY_PERIOD_POINT_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Over / Under Total Points 3Way`,
  THREE_WAY_POINT_HANDICAP: () => 'Point Spread 3Way',
  THREE_WAY_POINT_OVER_UNDER: () => 'Over / Under Total Points 3Way',

  // Baseball
  RUN_OVER_UNDER: () => 'Total Runs',
  RUN_OVER_UNDER_AFTER: (marketTemplate) => `Total Runs After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  TEAM_RUN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Runs`,
  TEAM_PERIOD_RUN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Total Runs`,
  TEAM_RUN_OVER_UNDER_AFTER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Runs After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  PERIOD_RUN_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Total Runs`,
  TEAM_STRIKEOUT_OVER_UNDER: (marketTemplate) => `Total ${teamFromIndex(marketTemplate.template.team)} Team Total Strikeouts`,
  HANDICAP_AND_OVER_UNDER: () => 'Spread and Total',
  FIRST_PITCHER_TOTAL_STRIKEOUTS: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team First Pitcher Total Strikeouts`,
  TEAM_STACK_ATTACK_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Stack Attacks`,
  RUN_HANDICAP: () => 'Spread',
  TWO_WAY_RESULT_AFTER: (marketTemplate) => `Moneyline After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  RUN_HANDICAP_AFTER: (marketTemplate) => `Spread After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  PERIOD_RUN_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Spread`,
  PLAYER_RUN_OVER_UNDER: () => 'Player Total Runs',
  THREE_WAY_RUN_OVER_UNDER: () => 'Total Runs 3Way',
  HIT_RESULT: () => 'Hit Result',
  HIT_RESULT_THREE_WAY: () => 'Hit Result 3Way',
  PERIOD_HIT_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Hit Result`,
  TWO_WAY_PERIOD_HIT_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Hit Result 2Way`,
  PERIOD_HITS: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Hits`,
  TEAM_PERIOD_HITS_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Total Hits`,
  TEAM_PERIOD_HOME_RUN: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Home Run`,
  TEAM_PERIOD_HIT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Hits`,
  TEAM_PERIOD_HITS: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Hits`,
  TEAM_PERIOD_STRIKEOUT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Total Strikeouts`,
  TEAM_PERIOD_PITCHES_BANDED: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Pitches Bands`,
  TEAM_PERIOD_PITCHES_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Total Pitches`,
  TEAM_PERIOD_RUN_SCORED: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning Run Scored`,
  TEAM_PERIOD_THREE_BATTERS_REACH_BASE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning - Will Three Batters Reach Base`,
  TEAM_PERIOD_THREE_BATTERS_STRIKEOUT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Inning - Will Three Batters Strikeout `,
  TEAM_HIT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Hits`,
  TEAM_RUN_BANDS: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Run Bands`,
  TEAM_RUN_BANDS_AFTER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Run Bands After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  TEAM_RUN_BANDS_AFTER_X: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Run Bands After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,
  PLAYER_BASES_OVER_UNDER: () => 'Player Total Bases',
  PLAYER_STRIKEOUT_OVER_UNDER: () => 'Player Total Strikeouts',
  PLAYER_HOME_RUN: () => 'Player Home Run',
  PLAYER_ON_BASE: () => 'Player on Base',
  PLAYER_ON_BASE_TWO_PLUS: () => 'Player on Base 2+',
  PITCH_OUTCOME: () => 'Pitch Outcome',
  PLATE_APPEARANCE_PITCHES_OVER_UNDER: () => 'Plate Appearance Total Pitches',
  PLATE_APPEARANCE_EXACT: () => 'Plate Appearance Exact',
  PLATE_APPEARANCE_GROUPED: () => 'Plate Appearance Grouped',
  PLATE_APPEARANCE_ON_BASE: () => 'Plate Appearance On Base',
  HIGHEST_SCORING_PERIOD_BASEBALL: () => 'Highest Scoring Inning',
  PERIOD_CORRECT_SCORE: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Correct Score`,
  PERIOD_HITS_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Total Hits`,
  PERIOD_RUN_SCORED: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Inning Run Scored`,
  HIT_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Hits`,
  TWO_WAY_HIT_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Hits 2Way`,
  RUN_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Runs`,
  TWO_WAY_RUN_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Runs 2Way`,
  NEXT_RUN: () => 'Next Run',
  RUN_BANDS: () => 'Run Bands',
  RUN_MARGIN: () => 'Run Margin',
  RUN_ODD_EVEN: () => 'Runs Odd / Even',
  HIT_OVER_UNDER: () => 'Total Hits',
  CORRECT_SCORE_NO_DRAW: () => 'Correct Score No Draw',
  TEAM_TO_SCORE_FIRST_WIN_MATCH: () => 'Team That Scores First to Win the Match',
  RUN_MARGIN_AFTER: (marketTemplate) => `Run Margin After ${ordinalFromNumber(marketTemplate.template.resultAfterX)} Inning`,

  // Hockey
  PERIOD_GOAL_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Spread`,
  THREE_WAY_PERIOD_GOAL_HANDICAP: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Spread 3Way`,
  PERIOD_GOAL_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Period Total Goals`,
  GOAL_HANDICAP: () => 'Spread',
  TEAM_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Goals`,
  NEXT_GOAL: () => 'Next Goal',
  TOTAL_GOALS_EXACT: () => 'Total Goals Exact',
  PLAYER_ANYTIME_GOALSCORER: () => 'Player Anytime Goalscorer',
  PLAYER_FIRST_GOALSCORER: () => 'Player First Goalscorer',
  PLAYER_NEXT_GOALSCORER: () => 'Player Next Goalscorer',
  PLAYER_LAST_GOALSCORER: () => 'Player Last Goalscorer',
  PLAYER_GOAL_OVER_UNDER: () => 'Player Total Goals',
  HIGHEST_SCORING_PERIOD_THREE: () => 'Highest Scoring Period 3Way',
  BOTH_TEAMS_TO_SCORE: () => 'Both Teams to Score',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_GOALS: (marketTemplate) => `Both Teams to Score ${marketTemplate.template.line} or More Goals`,
  DOUBLE_CHANCE: () => 'Double Chance',
  GOAL_MARGIN: () => 'Goals Margin',
  GOAL_ODD_EVEN: () => 'Goals Odd / Even',
  THREE_WAY_GOAL_RACE_TO_X: (marketTemplate) => `Race to ${marketTemplate.template.raceTo} Goals 3Way`,
  TEAM_PERIOD_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Period Total Goals`,

  // Football
  FIELD_GOAL_ODD_EVEN: () => 'Field Goals Odd / Even',
  FIELD_GOAL_OVER_UNDER: () => 'Total Field Goals',
  THREE_WAY_FIELD_GOAL_OVER_UNDER: () => 'Total Field Goals 3Way',
  THREE_WAY_FIELD_GOAL_RESULT: () => 'Field Goals Winner 3Way',
  TWO_WAY_FIELD_GOAL_RESULT: () => 'Field Goals Winner 2Way',
  HALF_FIELD_GOAL_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Field Goals Odd / Even`,
  HALF_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Field Goals`,
  THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Field Goals 3Way`,
  HALF_FIELD_GOAL_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Field Goals Winner`,
  THREE_WAY_HALF_FIELD_GOAL_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Field Goals Winner 3Way`,
  PERIOD_FIELD_GOAL_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Field Goals Odd / Even`,
  PERIOD_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Field Goals`,
  THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Field Goals 3Way`,
  PERIOD_FIELD_GOAL_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Field Goals Winner`,
  THREE_WAY_PERIOD_FIELD_GOAL_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Field Goals Winner 3Way`,
  TEAM_HALF_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Total Field Goals`,
  THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Total Field Goals 3Way`,
  TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Field Goals`,
  THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Field Goals 3Way`,
  POINT_HANDICAP_EXC_OT: () => 'Point Spread (Excl. Overtime)',
  POINT_OVER_UNDER_EXC_OT: () => 'Total Points (Excl. Overtime)',
  THREE_WAY_POINT_OVER_UNDER_EXC_OT: () => 'Total Points (Excl. Overtime) 3Way',
  THREE_WAY_TEAM_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Points 3Way`,
  PLAYER_PASSING_YARDS_OVER_UNDER: () => 'Total Player Passing Yards',
  PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER: () => 'Total Player Passing Touchdowns',
  PLAYER_RUSHING_YARDS_OVER_UNDER: () => 'Total Player Rushing Yards',
  PLAYER_RECEIVING_YARDS_OVER_UNDER: () => 'Total Player Receiving Yards',
  PLAYER_RECEPTIONS_OVER_UNDER: () => 'Total Player Receptions',
  HALF_POINT_HANDICAP_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Point Spread (Excl. Overtime) 2Way`,
  THREE_WAY_HALF_POINT_HANDICAP_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Point Spread (Excl. Overtime) 3Way`,
  PERIOD_POINT_HANDICAP_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Point Spread (Excl. Overtime) 2Way`,
  THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Point Spread (Excl. Overtime) 3Way`,
  HALF_POINT_OVER_UNDER_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Points (Excl. Overtime) 2Way`,
  THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.half)} Half Total Points (Excl. Overtime) 3Way`,
  HALF_RESULT_EXC_OT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.half)} Half Winner (Excl. Overtime)`,
  TWO_WAY_HALF_RESULT_EXC_OT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.half)} Half Winner (Excl. Overtime) 2Way`,
  PERIOD_POINT_OVER_UNDER_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Points (Excl. Overtime)`,
  THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Points (Excl. Overtime) 3Way`,
  PERIOD_RESULT_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Winner (Excl. Overtime)`,
  TWO_WAY_PERIOD_RESULT_EXC_OT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Winner (Excl. Overtime) 2Way`,
  THREE_WAY_TEAM_HALF_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Total Points 3Way`,
  TEAM_HALF_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Total Touchdowns 2Way`,
  THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.half)} Half Total Touchdowns 3Way`,
  THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Points 3Way`,
  TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Touchdowns 2Way`,
  THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Touchdowns 3Way`,
  THREE_WAY_TOUCHDOWN_OVER_UNDER: () => 'Total Touchdowns 3Way',
  TOUCHDOWN_OVER_UNDER: () => 'Total Touchdowns 2Way',
  TEAM_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Touchdowns 2Way`,
  THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Total Touchdowns 3Way`,
  PERIOD_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Touchdowns 2Way`,
  THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Total Touchdowns 3Way`,
  HALF_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Touchdowns 2Way`,
  THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Total Touchdowns 3Way`,
  HALF_TOUCHDOWN_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Touchdowns Odd / Even 2Way`,
  HALF_TOUCHDOWN_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Touchdowns Winner 2Way`,
  THREE_WAY_HALF_TOUCHDOWN_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Touchdowns Winner 3Way`,
  PERIOD_TOUCHDOWN_ODD_EVEN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Touchdowns Odd / Even`,
  PERIOD_TOUCHDOWN_RESULT: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Touchdowns Winner`,
  THREE_WAY_TOUCHDOWN_RESULT: () => 'Touchdowns Winner 3Way',
  TWO_WAY_TOUCHDOWN_RESULT: () => 'Touchdowns Winner 2Way',
  TOUCHDOWN_HIGHEST_SCORING_HALF: () => 'Highest Touchdown Scoring Half',
  TOUCHDOWN_HIGHEST_SCORING_PERIOD: () => 'Highest Touchdown Scoring Quarter',
  PLAYER_PASSING_ATTEMPTS_OVER_UNDER: () => 'Total Player Passing Attempts',
  PLAYER_PASSING_COMPLETIONS_OVER_UNDER: () => 'Total Player Passing Completions',
  PLAYER_TOUCHDOWN_X_OR_MORE: () => 'Player Will Score At Least One Touchdown',
  PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER: () => 'Total Player Rushing Yards and Passing',
  TOUCHDOWN_ODD_EVEN: () => 'Touchdowns Odd / Even',
  CURRENT_DRIVE_OUTCOME: () => 'Current Drive Outcome',
  PLAYER_NEXT_TOUCHDOWN_SCORER: () => 'Player Next Touchdown Scorer',
  THREE_WAY_POINT_HANDICAP_EXC_OT: () => 'Spread Excl. OT 3Way',
  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF: () => 'Both Teams to Score in Both Halves',
  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER: () => 'Both Teams to Score in Each Quarter',
  LOWEST_SCORING_HALF: () => 'Lowest Scoring Half',
  LOWEST_SCORING_PERIOD: () => 'Lowest Scoring Quarter',
  PERIOD_OF_FIRST_FIELD_GOAL: () => 'Quarter of First Field Goal',
  PERIOD_OF_FIRST_TOUCHDOWN: () => 'Quarter of First Touchdown',
  POINTS_IN_EACH_QUARTER: () => 'Points in Each Quarter',
  LONGEST_FIELD_GOAL_YARDS: () => 'Longest Yards Field Goal',
  PLAYER_ANYTIME_TOUCHDOWN_SCORER: () => 'Player Anytime Touchdown Scorer',
  PLAYER_LAST_TOUCHDOWN_SCORER: () => 'Player Last Touchdown Scorer',
  FOUR_WAY_RESULT_AND_POINT_OVER_UNDER: () => 'MoneyLine and Total 4Way',
  SIX_WAY_RESULT_AND_POINT_OVER_UNDER: () => 'MoneyLine and Total 6Way',
  CURRENT_DRIVE_OUTCOME_GROUPED: () => 'Current Drive Outcome Grouped',
  NEXT_DRIVE_OUTCOME: () => 'Next Drive Outcome',
  NEXT_DRIVE_OUTCOME_GROUPED: () => 'Next Drive Outcome Grouped',
  NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL: () => 'Next Field Goald attempt successful',
  NEXT_PLAY_IS_A_FIRST_DOWN: () => 'Next Play is a First Down',
  NEXT_PLAY_OFFENSIVE_TOUCHDOWN: () => 'Next Play is Offensive Touchdown',
  PUNT_YARDS_OVER_UNDER: () => 'Total Punt Yards',
  SITUATIONAL_EXTRA_POINT_CONVERSION_MADE: () => 'Situational Extra Point Conversion Made',
  SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE: () => 'Situational Field Goal Conversion Made',
  SITUATIONAL_TWO_POINTS_CONVERSION_MADE: () => 'Situational Two points Conversion Made',
  ANY_SCORELESS_QUARTER: () => 'Will There be Scoreless Quarter',
  BOTH_TEAMS_TO_SCORE_FIELD_GOAL: () => 'Both Teams to Score Field Goal',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS: () => 'Both Teams to Score X or More Field Goals',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS: () => 'Both Teams to Score X or More Points',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS: () => 'Both Teams to Score X or More Touchdowns',
  BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE: () => 'Both Teams to Score X or More Touchdowns and X or More Field Goals',
  DEFENSIVE_OR_SPECIAL_TOUCHDOWN: () => 'Will There be Defensive or Special Touchdown',
  FOURTH_DOWN_CONVERSION: () => 'Fourth Down Conversion',
  EITHER_TEAM_3_IN_A_ROW: () => 'Either Team Three in a Row',
  EITHER_TEAM_4_IN_A_ROW: () => 'Either Team Four in a Row',
  EITHER_TEAM_WIN_BY_THREE_OR_SEVEN: () => 'Either Team Win By Three or Seven',
  FIELD_GOAL_IN_EACH_PERIOD: () => 'Will there be Field Goal in Each Quarter',
  FIRST_DOWN_OVER_UNDER: () => 'Total First Downs',
  THREE_WAY_FIRST_DOWN_RESULT: () => 'First Down Moneyline 3Way',
  TWO_WAY_FIRST_DOWN_RESULT: () => 'First Down Moneyline 2Way',
  FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER: () => 'Total Yardage of the First Field Goal',
  FIRST_SCORE_YARDAGE_OVER_UNDER: () => 'Total Yardage of the First Score',
  FIRST_SCORE_TO_BE_TOUCHDOWN: () => 'Will the First Score be a Touchdown',
  FIRST_SCORING_PLAY_TYPE: () => 'First Scoring Type',
  LAST_SCORING_PLAY_TYPE: () => 'Last Scoring Type',
  FIRST_SECOND_HALF_SCORING_PLAY_TYPE: () => '2nd Half First Scoring Type',
  LEAD_CHANGE_IN_FOURTH_PERIOD: () => 'Will There be a Lead Change in the Fourth Quarter',
  LARGEST_LEAD_OF_GAME: () => 'Largest Lead of the Game',
  LAST_SCORE_TO_BE_TOUCHDOWN: () => 'Last Score to be a Touchdown',
  LONGEST_DRIVE_YARDS_OVER_UNDER: () => 'Total Longest Drive Yards',
  LONGEST_TOUCHDOWN_SCORED: () => 'Longest Touchdown Scored',
  PUNTS_OVER_UNDER: () => 'Total Punts',
  SAFETY_OCCURRED: () => 'Safety Occured',
  LEAD_CHANGE_IN_SECOND_HALF: () => 'Lead Change in Second Half',
  TWO_POINT_CONVERSION: () => 'Successful Two Point Conversion',
  FIRST_TEAM_TO_SCORE: () => 'First Team to Score',
  THREE_WAY_NEXT_TEAM_TO_SCORE: () => 'Next Team to Score 3Way',
  TWO_WAY_NEXT_TEAM_TO_SCORE: () => 'Next Team to Score 2Way',
  TEAM_TO_SCORE_FIRST_FIELD_GOAL: () => 'First Team to Score a Field Goal',
  TEAM_TO_SCORE_FIRST_LOSE_MATCH: () => 'First Team to Score Will Lose the match',
  TEAM_TO_SCORE_FIRST_TOUCHDOWN: () => 'First Team To Score a Touchdown',
  LAST_TEAM_TO_SCORE_WINS: () => 'Last Team to Score Will Win',
  TEAM_TO_SCORE_LONGEST_FIELD_GOAL: () => 'Team to Score the Longest Field Goal',
  TEAM_TO_SCORE_LONGEST_TOUCHDOWN: () => 'Team to Score the Longest Touchdown',
  GAME_TIED_AFTER_NIL_NIL: () => 'Game Tied After Nil-Nil',
  FIRST_SCORE_TIME: () => 'First Score Time',
  TOUCHDOWN_IN_EACH_PERIOD: () => 'Will There be a Touchdown in Each Quarter',
  TURNOVERS_OVER_UNDER: () => 'Total Turnovers',
  HALF_TIME_FULL_TIME_TIES_LOSE: () => 'Half Time/Full Time Ties Lose',
  TWO_WAY_HIGHEST_SCORING_HALF: () => 'Highest Scoring Half 2Way',
  LAST_TEAM_TO_SCORE: () => 'Last Team to Score',
  TEAM_WITH_HIGHEST_SCORING_HALF: () => 'Team With Highest Scoring Half',
  TEAM_WITH_HIGHEST_SCORING_PERIOD: () => 'Team With Highest Scoring Quarter',
  TWO_WAY_NEXT_TEAM_TO_GO_THREE_AND_OUT: () => 'Next Team to go Three and out 2Way',
  SCORE_IN_LAST_TWO_MINUTES_OF_HALF: (marketTemplate) => `Score in Last Two Minutes of ${ordinalFromNumber(marketTemplate.template.half)} Half`,
  NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Go Three and Out in Next Drive`,
  CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Go Three and Out in Current Drive`,
  DRIVE_CROSSES_YARD_LINE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Drive Crosses ${ordinalFromNumber(marketTemplate.template.yardline)} Yard Line`,
  TEAM_TO_LEAD_AFTER_EACH_QUARTER: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Lead After Each Quarter`,
  TEAM_TO_SCORE_IN_OWN_DRIVE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Score in Own Drive`,
  TEAM_NUMBER_OF_HALVES_WON: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Halves Won`,
  PERIOD_TEAM_TO_SCORE_FIRST: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Team to Score First`,
  TOUCHDOWN_IN_PERIOD: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Touchdown Scored`,
  TEAM_PERIOD_TO_SCORE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team ${ordinalFromNumber(marketTemplate.template.period)} Quarter To Score`,
  BOTH_TEAMS_TO_SCORE_IN_X_PERIOD: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.period)} Quarter Both Teams to Score`,
  HALF_TEAM_LAST_TO_SCORE: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Last Team to Score`,
  TEAM_FIRST_SCORING_PLAY_TYPE: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team First Scoring Type`,
  TEAM_FIRST_DRIVE_OUTCOME: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team First Drive Outcome`,
  TEAM_NUMBER_OF_PERIODS_WON: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team Number of Quarters Won`,
  TEAM_SCORE_IN_EACH_PERIOD: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Score in Each Quarter`,
  TEAM_TO_SCORE_X_OR_MORE_POINTS: (marketTemplate) => `${teamFromIndex(marketTemplate.template.team)} Team To Score X or More Points`,
  HALF_TEAM_TO_SCORE_FIRST_TOUCHDOWN: (marketTemplate) => `${ordinalFromNumber(marketTemplate.template.half)} Half Team To Score First Touchdown`,
  MOST_POINTS_IN_PERIOD: (marketTemplate) => `Most Points Will be in ${ordinalFromNumber(marketTemplate.template.period)} Quarter`,
  TOUCHDOWN_IN_TIME_BIN: (marketTemplate) => `Touchdown to be Scored Between ${ordinalFromNumber(marketTemplate.template.binMinuteStart)} and ${ordinalFromNumber(marketTemplate.template.binMinuteEnd)}`,
};

export const getMarketTemplateName = (marketTemplate) => {
  const nameResolver = MARKET_TEMPLATE_MAP[marketTemplate.template.code];
  if (!nameResolver) return marketTemplate.template.code;
  return nameResolver(marketTemplate);
};

export const getMarketTemplateDisplayPosition = (marketTemplate, displayConfiguration) => {
  const marketCode = marketTemplate?.template?.marketCode;
  const selections = marketTemplate?.template?.staticParams?.SELECTIONS || marketTemplate?.template?.selectionTemplates?.length;
  const marketDisplayConfiguration = displayConfiguration[`${marketCode}_${selections}`];
  if (!marketDisplayConfiguration) return 999;
  return marketDisplayConfiguration.position || 999;
};

export const getMarketTemplateDisplayName = (marketTemplate, displayConfiguration) => {
  const marketCode = marketTemplate?.template?.marketCode;
  const selections = marketTemplate?.template?.staticParams?.SELECTIONS || marketTemplate?.template?.selectionTemplates?.length;
  const marketDisplayConfiguration = displayConfiguration[`${marketCode}_${selections}`];
  const hasWaySuffix = !!find(displayConfiguration, (market) => market.marketCode === marketCode && market.selections !== selections);
  const startingBracketIndexes = [];
  const endingBracketIndexes = [];
  if (!marketDisplayConfiguration) return marketCode;
  const { longNamePattern } = marketDisplayConfiguration;
  for (let i = 0; i < longNamePattern?.length; i += 1) {
    if (longNamePattern[i] === '{') startingBracketIndexes.push(i);
    if (longNamePattern[i] === '}') endingBracketIndexes.push(i);
  }

  const paramsToReplace = [];

  for (let i = 0; i < startingBracketIndexes.length; i += 1) {
    paramsToReplace.push(longNamePattern.slice(startingBracketIndexes[i] + 1, endingBracketIndexes[i]));
  }
  const market = marketTemplate?.template;
  let displayName = longNamePattern;
  if (!paramsToReplace.length) {
    return hasWaySuffix ? `${displayName} ${selections}Way` : displayName;
  }
  each(paramsToReplace, (param) => {
    if (param === 'TEAM') {
      const team = market.staticParams?.TEAM === 0 ? 'Home team' : 'Away team';
      const teamName = includes(paramsToReplace, 'PLAYER_INDEX') ? 'Team' : team;
      displayName = displayName.replace('TEAM', teamName);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PLAYER_INDEX') {
      displayName = displayName.replace('PLAYER_INDEX', 'Player');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PITCHER_PLAYER_INDEX' || param === 'BATTER_PLAYER_INDEX') {
      displayName = displayName.replace(param, param === 'PITCHER_PLAYER_INDEX' ? 'Pitcher' : 'Batter');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'IS_TOP_INNING') {
      const isTopInningValue = market.staticParams?.IS_TOP_INNING === 0 ? 'Bottom' : 'Top';
      displayName = displayName.replace('IS_TOP_INNING', isTopInningValue);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (!market.staticParams?.[param]) {
      displayName = displayName.replace(param, `[${param}]`);
      displayName = displayName.replace(/{|}/g, '');
    } else {
      const valueToReplace = market.staticParams?.[param];
      const numberSuffix = marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL' ? MarketMapper.mapNumberSuffix(valueToReplace.toString()) : '';
      displayName = displayName.replace(param, `${valueToReplace}${numberSuffix}`);
      displayName = displayName.replace(/{|}/g, '');
    }
  });
  return hasWaySuffix ? `${displayName} ${selections}Way` : displayName;
};

export default {
  getMarketTemplateName,
  getMarketTemplateDisplayName,
};
